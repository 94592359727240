.Chart h1 {
  text-align: center;
  color: rgb(226, 228, 235);
}
.Chart .chart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 25px;
  color: rgb(226, 228, 235);
}
.Chart .chart {
  cursor: pointer !important;
}
.Chart .chart-title {
  cursor: pointer;
}
.Chart .change-name-container {
  display: flex;
  gap: 15px;
}
.Chart .change-name-container button {
  padding-bottom: 5px;
  border-color: #ff6961;
  border-radius: 5px;
  background-color: #ff6961;
}
.Chart .change-name-container button:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Chart .change-name-container input {
  border-radius: 5px;
  background-color: #bbbbbc;
}
.Chart .change-name-container input:active {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.Chart .change-name-container .hover-menu {
  background-color: green;
}/*# sourceMappingURL=Chart.css.map */